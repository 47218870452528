<template>

</template>

<script>
import {mapGetters} from "vuex";
import router from "@/routes/router";

export default {
  name: "AppRedirect",

  computed: {
    ...mapGetters({
      projects: "global/userProjects"
    })
  },

  created: function () {
    let initialProject = this.projects.length > 0 ? this.projects[0] : null;
    if (initialProject) {
      router.push('/projects/' + initialProject.uuid + '/preview');
    }
  }
}
</script>

<style scoped>

</style>
