<script>

import {Bar} from "vue-chartjs";
import ChartJSPluginDataLabels from "chartjs-plugin-datalabels";

export default {
  name: "CsBarChart",
  extends: Bar,
  props: ["data", "options"],
  mounted() {
    this.addPlugin(ChartJSPluginDataLabels);
    this.renderChart(this.data, this.options);
  }
}
</script>

<style scoped>

</style>
