<template>
  <div class="card chart-card">
    <div class="card-header">
      <h5>Packages By US States & International Cities</h5>
    </div>
    <div class="card-body">
      <el-table stripe
                style="width: 100%;"
                :data="data"
                border>

        <el-table-column v-for="column in stateTableColumns"
                         :key="column.label"
                         :min-width="column.minWidth"
                         align="center"
                         :sortable="allowSorting && column.sortable"
                         :prop="column.prop"
                         :label="column.label">
        </el-table-column>

      </el-table>
    </div>
    <div class="card-footer">
    </div>
  </div>
</template>

<script>
import {Table, TableColumn} from "element-ui";

export default {
  name: "PackagesByStateTable",
  props: ["data", "stateTableColumns", "allowSorting"],
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {}
  },

}
</script>

<style scoped>

</style>
