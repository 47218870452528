import Vue from 'vue'
import LightBootstrap from './light-bootstrap-main'

import App from './App.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import store from './store/index';
import router from './routes/router'

require('@/store/subscriber');

Vue.use(LightBootstrap);
Vue.use(VueAxios, axios);

Vue.axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;

Vue.axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (error.response.status === 401 && store.getters['auth/authenticated']) {
    store.dispatch("auth/handleUnAuthenticatedResponse").then(() => {
      router.push('/login');
    });
  }
  if (error.response.status === 403 && store.getters['auth/authenticated']) {
    router.push('/dashboard/access-denied');
  }
  return Promise.reject(error);
})


store.dispatch("auth/attempt", localStorage.getItem('token')).then(() => {
  if (store.getters['auth/authenticated']) {
    store.dispatch("global/index").then(() => {
      new Vue({
        el: '#app',
        render: h => h(App),
        router,
        store
      })
    });
  } else {
    store.dispatch("global/index").then(() => {
      new Vue({
        el: '#app',
        render: h => h(App),
        router,
        store
      })
    });
  }

});


