<template>
  <div class="container">
    <div class="overview">
      <h1>Hello, {{ getUserName() }}</h1>
      <p class="lead">Welcome back to your account.</p>
    </div>
  </div>
</template>
<script>
import {mapGetters} from 'vuex';

export default {
  data() {
    return {}
  },

  computed: {
    ...mapGetters({
      user: "auth/user",
      token: "auth/token"
    })
  },

  methods: {
    getUserName() {
      if (this.user)
        return this.user.first_name + " " + this.user.last_name;
      else return "";
    }
  }
}
</script>
<style scoped lang="scss">
.overview {
  min-height: calc(100vh - 160px);
  display: flex;
  padding-bottom: 150px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
