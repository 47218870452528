<template>
  <auth-layout pageClass="login-page">
    <ValidationObserver v-slot="{ handleSubmit }">
      <!--You can specify transitions on initial render. The `card-hidden` class will be present initially and then it will be removed-->
      <form @submit.prevent="handleSubmit(submit)">
        <fade-render-transition>
          <card>
            <div slot="header">
              <h4 class="card-title text-center">Forget your password</h4>
            </div>
            <div>
              <p class="card-description">no worries, just enter your email below and we’ll send you an email to reset
                it right away!</p>

              <ValidationProvider
                name="email"
                rules="required|email"
                v-slot="{ passed, failed,errors }"
              >
                <fg-input type="email"
                          :error="failed ? errors[0] : null"
                          :hasSuccess="passed"
                          :label="'Your Email'"
                          name="email"
                          v-model="formData.email">
                </fg-input>
              </ValidationProvider>

            </div>
            <div class="text-center">
              <button type="submit" :disabled="submitting" class="btn btn-fill btn-default btn-round btn-wd ">Send
              </button>
              <br>
              <div class="forgot">
                <router-link to="/login" class="card-category">
                  Back
                </router-link>
              </div>
            </div>
          </card>
        </fade-render-transition>
      </form>
    </ValidationObserver>
  </auth-layout>
</template>
<script>
import {Checkbox as LCheckbox, FadeRenderTransition} from 'src/components/index'
import AuthLayout from './AuthLayout.vue'
import {extend} from "vee-validate";
import {required, email} from "vee-validate/dist/rules";

extend("email", email);
extend("required", required);

export default {
  components: {
    LCheckbox,
    FadeRenderTransition,
    AuthLayout
  },
  data() {
    return {
      submitting: false,
      formData: {
        email: ""
      }
    };
  },

  methods: {
    submit() {
      this.submitting = true;
      this.axios.post("auth/forget-password", this.formData).then((res) => {
        this.$notify({
          message: "Please check your email to reset password.",
          timeout: 10000,
          type: 'success'
        })
      }).catch((error) => {
        this.$notify({
          message: error.response.data.message,
          timeout: 5000,
          type: 'danger'
        })
      }).finally(() => {
        this.submitting = false;
      });
    }
  }
}
</script>
<style>
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}

.card-description {
  color: #747171;
  opacity: 0.9;
}
</style>
