import DashboardLayout from "@/pages/Dashboard/Layout/DashboardLayout";

let projectsRoutes = {
  path: '/projects',
  component: DashboardLayout,
  redirect: '/projects/:uuid/preview',
  children: [
    {
      path: ':uuid/preview',
      name: 'Project Charts',
      component: () => import('@/pages/Dashboard/projects/ProjectChartList')
    },
    {
      path: ':uuid/packages-details',
      name: 'Project Details',
      component: () => import('@/pages/Dashboard/projects/ProjectPackagesDetails')
    },
    {
      path: ':uuid/documents',
      name: 'Project Documents',
      component: () => import('@/pages/Dashboard/projects/ProjectDocuments')
    },
  ]
}

export default projectsRoutes;
