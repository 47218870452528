var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('auth-layout',{attrs:{"pageClass":"login-page"}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('fade-render-transition',[_c('card',[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('h3',{staticClass:"card-title text-center"},[_vm._v("Reset Password")])]),_c('div',[_c('p',{staticClass:"card-description"},[_vm._v("Just enter your new password and you’re good to go!")]),_c('ValidationProvider',{attrs:{"vid":"password","rules":"required","name":"The Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"type":"password","error":failed ? errors[0]: null,"hasSuccess":passed,"label":"Password"},model:{value:(_vm.formData.password),callback:function ($$v) {_vm.$set(_vm.formData, "password", $$v)},expression:"formData.password"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required|confirmed:password","name":"The Confirmed Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"type":"password","error":failed ? errors[0]: null,"hasSuccess":passed,"label":"Confirmed Password","name":"confirm"},model:{value:(_vm.formData.confirmed_password),callback:function ($$v) {_vm.$set(_vm.formData, "confirmed_password", $$v)},expression:"formData.confirmed_password"}})]}}],null,true)})],1),_c('div',{staticClass:"text-center"},[_c('button',{staticClass:"btn btn-fill btn-default btn-round btn-wd ",attrs:{"type":"submit","disabled":_vm.submitting}},[_vm._v("Reset ")])])])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }