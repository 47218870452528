<template>
  <div class="access-denied">
    <div class="centered">
      <h1><i class="not-found-icon nc-icon nc-puzzle-10"></i>403</h1>
      <p>You don't have permission to do such action</p>
    </div>
  </div>

</template>

<script>
export default {}
</script>
<style scoped lang="scss">
.access-denied {

  .centered {
    min-height: calc(100vh - 160px);

    h1, i {
      font-size: 50px;
    }

    p {
      font-size: 20px;
    }

    display: flex;
    padding-bottom: 150px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: black;
  }


  .not-found-icon {
    margin-right: 10px;
  }

}

</style>
