<template>

  <div class="wrapper" :class="{'nav-open': $sidebar.showSidebar}">
    <notifications></notifications>

    <side-bar :background-image="getImage()">

      <user-menu></user-menu>

      <mobile-menu></mobile-menu>

      <template slot-scope="props" slot="links">

        <sidebar-item :link="{name: 'Dashboard', icon: 'nc-icon nc-chart-pie-35', path: '/overview'}">
        </sidebar-item>

        <sidebar-item :expandable="true" :link="{name: 'Company', icon: 'nc-icon nc-bank'}">
          <side-bar-tree :node="userCompany"></side-bar-tree>
        </sidebar-item>

        <sidebar-item :link="{name: 'Analytics', icon: 'nc-icon nc-chart-pie-36', path: '/analytics'}">
        </sidebar-item>

      </template>

    </side-bar>


    <div class="main-panel">
      <top-navbar></top-navbar>

      <dashboard-content @click.native="toggleSidebar"></dashboard-content>

      <content-footer></content-footer>
    </div>

  </div>
</template>
<script>
import TopNavbar from './TopNavbar.vue'
import ContentFooter from './ContentFooter.vue'
import DashboardContent from './Content.vue'
import MobileMenu from './Extra/MobileMenu.vue'
import UserMenu from './Extra/UserMenu.vue'
import SideBarTree from "@/pages/Dashboard/Layout/SideBarTree";
import {mapGetters} from "vuex";

export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    MobileMenu,
    UserMenu,
    SideBarTree
  },
  data() {
    return {}
  },

  computed: {
    ...mapGetters({
      userCompany: "global/userCompany",
      sideBarImage:"global/sideBarImage"
    })
  },

  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false)
      }
    },

    getImage(){
      return process.env.VUE_APP_SERVER_IMAGE_URL + this.sideBarImage;
    }

  }
}

</script>
