import Checkbox from './Inputs/Checkbox.vue'
import Radio from './Inputs/Radio.vue'
import FormGroupInput from './Inputs/formGroupInput.vue'
import FormGroupTextArea from './Inputs/formGroupTextArea.vue'
import Switch from './Switch.vue'
import Progress from './Progress.vue'

import DropDown from './Dropdown.vue'
import Table from './Table.vue'

import FadeRenderTransition from './Transitions/FadeRenderTransition.vue'

import Card from './Cards/Card.vue'
import ChartCard from './Cards/ChartCard.vue'
import StatsCard from './Cards/StatsCard.vue'

import Breadcrumb from './Breadcrumb/Breadcrumb.vue'
import BreadcrumbItem from './Breadcrumb/BreadcrumbItem.vue'

import Pagination from './Pagination.vue'
import NewPagination from './NewPagination.vue'
import DeleteModal from './DeleteModal.vue'
import AvatarUploader from './AvatarUploader.vue'

import SidebarPlugin from './SidebarPlugin'
import DeleteAllModal from "@/components/DeleteAllModal";
import ImageUploader from "@/components/ImageUploader";
import CsBarChart from "@/components/chartjs/CsBarChart";
import CsPieChart from "@/components/chartjs/CsPieChart";

let components = {
  Checkbox,
  Switch,
  Progress,
  Pagination,
  Radio,
  FormGroupInput,
  Card,
  ChartCard,
  StatsCard,
  Table,
  DropDown,
  SidebarPlugin,
  FadeRenderTransition,
  Breadcrumb,
  BreadcrumbItem,
  NewPagination,
  DeleteModal,
  DeleteAllModal,
  AvatarUploader,
  FormGroupTextArea,
  ImageUploader,
  CsBarChart,
  CsPieChart
}
export {
  Checkbox,
  Switch,
  Progress,
  Pagination,
  Radio,
  FormGroupInput,
  Card,
  ChartCard,
  StatsCard,
  Table,
  DropDown,
  SidebarPlugin,
  FadeRenderTransition,
  Breadcrumb,
  BreadcrumbItem,
  NewPagination,
  DeleteModal,
  DeleteAllModal,
  AvatarUploader,
  FormGroupTextArea,
  ImageUploader,
  CsBarChart,
  CsPieChart
}

export default components
