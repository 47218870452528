<template>
  <auth-layout pageClass="login-page">
    <ValidationObserver v-slot="{ handleSubmit }">
      <!--You can specify transitions on initial render. The `card-hidden` class will be present initially and then it will be removed-->
      <form @submit.prevent="handleSubmit(submit)">
        <fade-render-transition>
          <card>
            <div slot="header">
              <h3 class="card-title text-center">Login</h3>
            </div>
            <div>
              <ValidationProvider
                name="email"
                rules="required|email"
                v-slot="{ passed, failed,errors }"
              >
                <fg-input type="email"
                          :error="failed ? errors[0] : null"
                          :hasSuccess="passed"
                          label="Email address"
                          name="email"
                          v-model="formData.email">
                </fg-input>
              </ValidationProvider>
              <ValidationProvider
                name="password"
                rules="required"
                v-slot="{ passed, failed,errors}"
              >
                <fg-input type="password"
                          :error="failed ? errors[0]: null"
                          :hasSuccess="passed"
                          name="password"
                          label="Password"
                          v-model="formData.password">
                </fg-input>
              </ValidationProvider>

            </div>
            <div class="text-center">
              <button type="submit" :disabled="submitting" class="btn btn-fill btn-default btn-round btn-wd ">Login
              </button>
              <br>
              <div class="forgot">
                <router-link to="/forget-password" class="card-category">
                  Forgot your password?
                </router-link>
              </div>
            </div>
          </card>
        </fade-render-transition>
      </form>
    </ValidationObserver>
  </auth-layout>
</template>
<script>
import {Checkbox as LCheckbox, FadeRenderTransition} from 'src/components/index'
import AuthLayout from './AuthLayout.vue'
import {extend} from "vee-validate";
import {required, email} from "vee-validate/dist/rules";
import {mapActions} from 'vuex';
import Vue from "vue";

extend("email", email);
extend("required", required);

export default {
  components: {
    LCheckbox,
    FadeRenderTransition,
    AuthLayout
  },
  data() {
    return {
      submitting: false,
      formData: {
        email: "",
        password: ""
      }
    };
  },

  methods: {
    ...mapActions({
      signIn: "auth/signIn",
      index: "global/index"
    }),
    submit() {
      this.submitting = true;
      this.signIn(this.formData).then(() => {
        this.index().then(() => {
          this.$router.push("/");
        })
      }).catch((err) => {
        this.$notify({
          message: "Invalid Credentials",
          timeout: 2000,
          type: 'danger'
        });
      }).finally(() => {
        this.submitting = false;
      });
    }
  }
}
</script>
<style>
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}
</style>
