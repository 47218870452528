import DashboardLayout from '@/pages/Dashboard/Layout/DashboardLayout.vue'
import NotFound from '@/pages/GeneralViews/NotFoundPage.vue'
import Overview from '@/pages/Dashboard/Dashboard/Overview.vue'
import Login from '@/pages/Dashboard/Pages/Login.vue';
import UpdatePassword from "@/pages/Dashboard/profile/UpdatePassword";
import UpdateProfile from "@/pages/Dashboard/profile/UpdateProfile";
import ForgetPassword from "@/pages/Dashboard/Pages/ForgetPassword";
import ResetPassword from "@/pages/Dashboard/Pages/ResetPassword";
import DashboardNotFoundPage from "@/pages/GeneralViews/DashboardNotFoundPage";
import AccessDeniedPage from "@/pages/GeneralViews/AccessDeniedPage";
import companiesRoutes from "@/routes/companiesRoutes";
import projectsRoutes from "@/routes/projectsRoutes";
import MemberChartList from "@/pages/Dashboard/projects/MemberChartList";
import AppRedirect from "@/pages/Dashboard/Dashboard/AppRedirect";

let dashboardRoutes = {
  path: '/',
  component: DashboardLayout,
  redirect: '/app-redirect',
  children: [
    {
      path: 'app-redirect',
      name: 'Redirect',
      component: AppRedirect,
    },
    {
      path: 'overview',
      name: 'Overview',
      component: Overview,
    },
    {
      path: 'analytics',
      name: 'Analytics',
      component: MemberChartList,
    },
    {
      path: 'change-my-password',
      name: 'Update Password',
      component: UpdatePassword,
    },
    {
      path: 'update-profile',
      name: 'Update Profile',
      component: UpdateProfile,
    },
    {
      path: '/dashboard/not-found',
      name: 'Not Found Page',
      component: DashboardNotFoundPage
    },
    {
      path: '/dashboard/access-denied',
      name: 'Access Denied',
      component: AccessDeniedPage
    },
  ],

};

let loginPage = {
  path: '/login',
  name: 'Login',
  component: Login
};

let forgetPasswordPage = {
  path: '/forget-password',
  name: 'Forget Password',
  component: ForgetPassword
};

let resetPassword = {
  path: '/reset-password/:token',
  name: 'Reset Password',
  component: ResetPassword
};


const routes = [
  {
    path: '/',
    redirect: '/app-redirect'
  },
  loginPage,
  forgetPasswordPage,
  resetPassword,
  dashboardRoutes,
  companiesRoutes,
  projectsRoutes,
  {path: '*', name: 'Not Found', component: NotFound}
]

export default routes
