import DashboardLayout from "@/pages/Dashboard/Layout/DashboardLayout";

let companiesRoutes = {
  path: '/companies',
  component: DashboardLayout,
  redirect: '/companies/:uuid/projects',
  children: [
    {
      path: ':uuid/projects',
      name: 'Company Projects',
      component: () => import('@/pages/Dashboard/companies/companyProjects')
    }
  ]
}

export default companiesRoutes;
