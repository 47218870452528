<template>
  <div class="card chart-card">
    <div class="card-header">
      <h5>Shipped Packages ({{ shippedPercentage }})</h5>
    </div>
    <div class="card-body">
      <cs-pie-chart :data="data" :options="options"></cs-pie-chart>
    </div>
    <div class="card-footer">
      <p v-if="projectId && numberOfShippedPackages > 0">
        <router-link
          style="color:#23ccef"
          :to="'/projects/'+projectId+'/packages-details'">More Details</router-link>
      </p>
    </div>
  </div>
</template>
<script>
import {CsPieChart} from "@/components";

export default {
  name: "StatusDataPieChart",
  components: {
    CsPieChart
  },
  props: {
    "data": {type: Object},
    "numberOfShippedPackages": {type: Number},
    "totalNumberOfPackages": {type: Number},
    "projectId": {
      type: String,
      required: false,
      default: null
    }

  },
  computed: {
    shippedPercentage() {
      return ((this.numberOfShippedPackages / this.totalNumberOfPackages) * 100).toFixed(2) + "%";
    }
  },
  data() {
    return {
      options: {
        maintainAspectRatio: false,
        borderWidth: "10px",
        hoverBackgroundColor: "red",
        hoverBorderWidth: "10px",
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              try {
                let label = ' ' + data.labels[tooltipItem.index] || '';

                if (label) {
                  label += ': ';
                }

                const sum = data.datasets[0].data.reduce((accumulator, curValue) => {
                  return accumulator + curValue;
                });
                const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];

                label += Number((value / sum) * 100).toFixed(2) + '%';
                return label;
              } catch (error) {
                console.log(error);
              }
            }
          }
        },
        plugins: {
          datalabels: {
            formatter: (value, ctx) => {
              if (value === 0) return '';
              let sum = 0;
              let dataArr = ctx.chart.data.datasets[0].data;
              dataArr.map(data => {
                sum += data;
              });
              return (value * 100 / sum).toFixed(2) + "%";
            },
            color: 'white',
            textAlign: 'center',
            font: {
              weight: "bold",
              size: 14
            }
          }
        },
      }
    }
  },
}
</script>

<style scoped>

</style>
