import axios from 'axios';

export default {
  namespaced: true,

  state: {
    userCompany: null,
    userProjects: null,
    welcomeImage: null,
    sideBarImage: null,
    welcomePageText: null
  },

  getters: {
    userCompany(state) {
      return state.userCompany;
    },

    userProjects(state) {
      return state.userProjects;
    },

    welcomeImage(state) {
      return state.welcomeImage;
    },

    sideBarImage(state) {
      return state.sideBarImage;
    },

    welcomePageText(state) {
      return state.welcomePageText;
    }

  },

  mutations: {
    SET_USER_COMPANY(state, userCompany) {
      state.userCompany = userCompany;
    },

    SET_USER_PROJECTS(state, userProjects) {
      state.userProjects = userProjects;
    },

    SET_WELCOME_IMAGE(state, welcomeImage) {
      state.welcomeImage = welcomeImage;
    },

    SET_SIDE_BAR_IMAGE(state, sideBarImage) {
      state.sideBarImage = sideBarImage;
    },

    SET_WELCOME_PAGE_TEXT(state,welcomePageText){
      state.welcomePageText=welcomePageText;
    }

  },

  actions: {

    async index({commit, state}) {
      try {
        let res = await axios.get("index");
        commit('SET_USER_COMPANY', res.data.companies);
        commit('SET_USER_PROJECTS', res.data.projects);
        commit('SET_WELCOME_IMAGE', res.data.images.welcome_page_image);
        commit('SET_SIDE_BAR_IMAGE', res.data.images.side_bar_image);
        commit('SET_WELCOME_PAGE_TEXT',res.data.welcomePageText);
      } catch (e) {
        commit('SET_TOKEN', null);
        commit('SET_USER', null);
      }

    }

  }
}
