<template>
  <div class="card chart-card">
    <div class="card-header">
      <h5>Packages By US States</h5>
    </div>
    <div class="card-body">
      <GChart
        :settings="{ packages: ['geochart'] ,mapsApiKey:mapKey}"
        type="GeoChart"
        :data="data"
        :options="chartOptions"
        :resizeDebounce="500"
      />
    </div>
    <div class="card-footer">
    </div>
  </div>
</template>
<script>
import {GChart} from 'vue-google-charts'

export default {
  name: "UsaDataGeoChart",
  components: {
    GChart
  },
  props: ["data", "numberOfShippedPackages"],

  data() {
    return {
      mapKey: process.env.GOOGLE_MAP_KEY,
      chartOptions: {
        region: 'US',
        displayMode: 'regions',
        resolution: 'provinces',
        colorAxis: {colors: ["#a1829b","#7cc6d8","#9ccb6f","#edc260","#e98c7b"]},
        legend: 'none',
        height: 500,
        keepAspectRatio: true,
        tooltip: {
          isHtml: true
        }
      }
    }
  }

}
</script>

<style scoped>

</style>
