<template>
  <div class="card vue-avatar-cropper-demo text-center">
    <div class="card-body">
      <img :src="getPath()" alt="Image" class="card-img avatar"/>
      <div class="card-img-overlay">
        <button class="btn btn-info btn-sm" id="pick-avatar">Select a new image</button>
      </div>
    </div>
    <div class="card-footer text-muted" v-html="message"></div>
    <avatar-cropper
      @uploading="handleUploading"
      @uploaded="handleUploaded"
      @error="handlerError"
      mimes="image/png, image/gif, image/jpeg, image/bmp, image/x-icon"
      trigger="#pick-avatar"
      upload-form-name="photo"
      :upload-headers=headers
      :labels=labels
      :upload-url=uploadUrl />
  </div>

</template>

<script>
import AvatarCropper from "vue-avatar-cropper";

export default {
  name: "AvatarUploader",
  components: {AvatarCropper},
  props: {
    path: {
      type: String,
    },
  },

  data() {
    return {
      message: "",
      labels: {"submit": "Submit", "cancel": "Cancel"},
      uploadUrl: process.env.VUE_APP_UPLOAD_IMAGE_URL,
      headers: {
        'Authorization': "Bearer " + this.$store.getters["auth/token"]
      }
    };

  },

  methods: {

    getPath() {
      if (this.path === "" || this.path === null) {
        return "/static/img/avatar.png";
      } else {
        return process.env.VUE_APP_SERVER_IMAGE_URL + this.path;
      }
    },

    handleUploading(form, xhr) {
      this.message = "uploading...";
    },
    handleUploaded(response) {
      this.$emit('imageUploaded', response.data.url);
      this.message = "";
    },
    handlerError(message, type, xhr) {
      this.message = "Oops! Something went wrong...";
    }
  }
};
</script>

<style scoped>
.vue-avatar-cropper-demo {
  max-width: 18em;
  margin: 0 auto;
}

.avatar {
  width: 160px;
  border-radius: 6px;
  display: block;
  margin: 20px auto;
}

.card-img-overlay {
  display: none;
  transition: all 0.5s;
}

.card-img-overlay button {
  margin-top: 50%;
}

.vue-avatar-cropper-demo:hover .card-img-overlay {
  display: block;
}
</style>
