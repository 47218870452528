<template>
  <div class="user">
    <div class="photo">
      <img :src="getProfileImage()" alt="avatar"/>
    </div>
    <div class="info">
      <a data-toggle="collapse" href="#">
           <span>
             {{ getUserName() }}
          </span>
      </a>
      <div class="clearfix"></div>
    </div>
  </div>
</template>
<script>
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition'
import {mapGetters} from 'vuex';

export default {
  components: {
    [CollapseTransition.name]: CollapseTransition
  },

  data() {
    return {}
  },

  computed: {
    ...mapGetters({
      user: "auth/user"
    })
  },

  methods: {
    getUserName() {
      if (this.user)
        return this.user.first_name + " " + this.user.last_name;
      else return "";
    },
    getProfileImage() {
      if (this.user && this.user.profile_image)
        return process.env.VUE_APP_SERVER_IMAGE_URL + this.user.profile_image;
      else if (this.user && this.user.company && this.user.company.logo)
        return process.env.VUE_APP_SERVER_IMAGE_URL + this.user.company.logo;
      else return "/static/img/avatar.png";
    }
  }

}
</script>
<style>
.collapsed {
  transition: opacity 1s;
}
</style>
