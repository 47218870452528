import axios from 'axios';

export default {
  namespaced: true,

  state: {
    token: null,
    user: null
  },

  getters: {

    token(state) {
      return state.token;
    },

    authenticated(state) {
      return state.token && state.user;
    },

    user(state) {
      return state.user;
    }

  },

  mutations: {

    SET_TOKEN(state, token) {
      state.token = token;
    },

    SET_USER(state, user) {
      state.user = user;
    },

  },

  actions: {

    async signIn({dispatch}, formData) {
      let res = await axios.post("auth/login", formData);
      return dispatch('attempt', res.data.access_token);
    },

    async attempt({commit, state}, token) {

      if (token) {
        commit('SET_TOKEN', token);
      }

      if (!state.token) {
        return;
      }

      try {
        let res = await axios.post("auth/me");
        commit('SET_USER', res.data);
      } catch (e) {
        commit('SET_TOKEN', null);
        commit('SET_USER', null);
      }

    },

    async updateUserData({commit, state}) {
      if (!state.token) {
        return;
      }
      try {
        let res = await axios.post("auth/me");
        commit('SET_USER', res.data);
      } catch (e) {
        commit('SET_TOKEN', null);
        commit('SET_USER', null);
      }
    },

    logout({commit}) {
      return axios.post("auth/logout").then(() => {
        commit('SET_TOKEN', null);
        commit('SET_USER', null);
      });
    },

    async handleUnAuthenticatedResponse({commit}) {
      commit('SET_TOKEN', null);
      commit('SET_USER', null);
    }

  }
}
