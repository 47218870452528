<template>
  <div class="card chart-card">
    <div class="card-header">
      <h5>Total Number Of Shipped Packages ({{ numberOfShippedPackages }})</h5>
    </div>
    <div class="card-body">
      <cs-bar-chart :data="data" :options="options"></cs-bar-chart>
    </div>
    <div class="card-footer">
      <p v-if="projectId && numberOfShippedPackages > 0">
        <router-link style="color:#23ccef"
                     :to="'/projects/'+projectId+'/packages-details'">More Details
        </router-link>
      </p>
    </div>
  </div>
</template>
<script>
import {CsBarChart} from "@/components";

export default {
  name: "ShippedDataBarChart",
  components: {
    CsBarChart
  },
  props: {
    "data": {type: Object},
    "numberOfShippedPackages": {type: Number},
    "projectId": {
      type: String,
      required: false,
      default: null
    }
  },
  data() {
    return {
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            },
            gridLines: {
              display: true
            }
          }],
          xAxes: [{
            ticks: {
              beginAtZero: true
            },
            gridLines: {
              display: false
            }
          }]
        },
        legend: {
          display: false
        },
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
            color: 'white',
            textAlign: 'center',
            font: {
              weight: "bold",
              size: 14
            }
          }
        },
      }
    }
  },
}
</script>

<style scoped>

</style>
