<template>
  <fragment v-if="requestDone">

    <div class="row">

      <div class="col-md-8">
        <div class="card chart-card" style="height: 90%">
          <div class="card-header" style="text-align: center">
            <h4 style="font-size:1.1rem">Delivery Percentage By Project</h4>
          </div>

          <div class="card-body">

            <div v-for="project in projectsDeliveryPercentage"
                 class="project_delivery_percentage">
              <div class="project_title"><h4>{{ project.project_title }}:</h4></div>
              <div class="project_progress">

                <el-progress :percentage="project.percentage"
                             :stroke-width="16"
                             :color="'#87CB16'"></el-progress>

              </div>
            </div>
          </div>
          <div class="card-footer">
          </div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="card chart-card" style="height: 90%">
          <div class="card-header" style="text-align: center">
            <h4 style="font-size: 1.1rem">Delivery Percentage</h4>
          </div>
          <div class="card-body" style="text-align: center">
            <el-progress :width="200"  type="circle" :stroke-width="20" :color="'#87CB16'"
                         :percentage="deliveryPercentage"></el-progress>
          </div>
          <div class="card-footer">
          </div>
        </div>
      </div>

    </div>

    <div class="row">
      <div class="col-md-12">
        <delivered-last-year-bar-chart :data="getDeliveredLastYearBarChartData">
        </delivered-last-year-bar-chart>
      </div>
    </div>

    <div class="row">
      <div class="col-md-8">
        <shipped-data-bar-chart :data="getNumberOfShippedBarChartData"
                                :total-number-of-packages="getTotalNumbersOfItems">
        </shipped-data-bar-chart>
      </div>
      <div class="col-md-4">
        <shipped-data-pie-chart :data="getNumberOfShippedBarChartData"
                                :number-of-shipped-packages="getTotalNumbersOfShippedItems"
                                :total-number-of-packages="getTotalNumbersOfItems">
        </shipped-data-pie-chart>
      </div>
    </div>

    <div class="row">
      <div class="col-md-8">
        <status-data-bar-chart :data="getStatusBarChartData"
                               :number-of-shipped-packages="getTotalNumbersOfShippedItems">
        </status-data-bar-chart>
      </div>

      <div class="col-md-4">
        <status-data-pie-chart :data="getStatusPieChartData"
                               :total-number-of-packages="getTotalNumbersOfItems"
                               :number-of-shipped-packages="getTotalNumbersOfShippedItems">
        </status-data-pie-chart>
      </div>
    </div>


    <div class="row">
      <div class="col-md-12">
        <countries-data-geo-chart :data="countriesChartData"
                                  :number-of-shipped-packages="getTotalNumbersOfShippedItems">
        </countries-data-geo-chart>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <top-countries :data="topCountriesData"  :allow-sorting="true" :state-table-columns="topCountriesColumns"></top-countries>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <usa-data-geo-chart :data="usaChartData"
                            :number-of-shipped-packages="getTotalNumbersOfShippedItems">
        </usa-data-geo-chart>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <top-usa-states :data="topUsaStatesData" :allow-sorting="true" :state-table-columns="topUsaStatesColumns"></top-usa-states>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <PackagesByStateTable :data="stateTableData" :allow-sorting="true" :state-table-columns="stateTableColumns"></PackagesByStateTable>
      </div>
    </div>

  </fragment>
</template>

<script>
import ShippedDataPieChart from "@/pages/Dashboard/projects/charts/ShippedDataPieChart";
import ShippedDataBarChart from "@/pages/Dashboard/projects/charts/ShippedDataBarChart";
import StatusDataPieChart from "@/pages/Dashboard/projects/charts/StatusDataPieChart";
import StatusDataBarChart from "@/pages/Dashboard/projects/charts/StatusDataBarChart";
import PackagesByStateTable from "@/pages/Dashboard/projects/tables/PackagesByStateTable";
import CountriesDataGeoChart from "@/pages/Dashboard/projects/charts/CountriesDataGeoChart";
import UsaDataGeoChart from "@/pages/Dashboard/projects/charts/UsaDataGeoChart";
import TopCountries from "@/pages/Dashboard/projects/tables/TopCountries";
import TopUsaStates from "@/pages/Dashboard/projects/tables/TopUsaStates";
import DeliveredLastYearBarChart from "@/pages/Dashboard/projects/charts/DeliveredLastYearBarChart";
import {Progress} from "element-ui";

export default {

  components: {
    ShippedDataPieChart,
    ShippedDataBarChart,
    StatusDataPieChart,
    StatusDataBarChart,
    [Progress.name]: Progress,
    PackagesByStateTable,
    CountriesDataGeoChart,
    UsaDataGeoChart,
    TopCountries,
    TopUsaStates,
    DeliveredLastYearBarChart
  },

  data() {
    return {
      loader: {},
      numberOfShippedPieChartData: [],
      numberOfShippedBarChartData: [],
      statusPieChart: [],
      statusBarChart: [],
      stateTableData: [],
      stateTableColumns: [],
      deliveryPercentage: 0,

      countriesChartData: [],
      usaChartData: [],

      topCountriesColumns: [],
      topCountriesData: [],

      topUsaStatesColumns: [],
      topUsaStatesData: [],

      deliveredLastYear: [],
      projectsDeliveryPercentage: [],
      requestDone: false
    }
  },

  created() {
    this.getData();
  },

  computed: {
    getNumberOfShippedPieChartData() {
      return {
        hoverBackgroundColor: "red",
        hoverBorderWidth: 10,
        labels: this.numberOfShippedPieChartData.labels,
        datasets: [
          {
            backgroundColor: this.numberOfShippedPieChartData.colors,
            data: this.numberOfShippedPieChartData.data
          }
        ]
      };
    },

    getNumberOfShippedBarChartData() {
      return {
        hoverBackgroundColor: "red",
        hoverBorderWidth: 10,
        labels: this.numberOfShippedBarChartData.labels,
        datasets: [
          {
            backgroundColor: this.numberOfShippedBarChartData.colors,
            data: this.numberOfShippedBarChartData.data
          }
        ]
      };
    },

    getDeliveredLastYearBarChartData() {
      return {
        hoverBackgroundColor: "red",
        hoverBorderWidth: 10,
        labels: this.deliveredLastYear.labels,
        datasets: [
          {
            backgroundColor: this.deliveredLastYear.colors,
            data: this.deliveredLastYear.data
          }
        ]
      };
    },

    getTotalNumbersOfItems() {
      return this.numberOfShippedBarChartData.data.reduce((a, b) => a + b, 0)
    },

    getStatusPieChartData() {
      return {
        hoverBackgroundColor: "red",
        hoverBorderWidth: 10,
        labels: this.statusPieChart.labels,
        datasets: [
          {
            backgroundColor: this.statusPieChart.colors,
            data: this.statusPieChart.data
          }
        ]
      };
    },

    getStatusBarChartData() {
      return {
        hoverBackgroundColor: "red",
        hoverBorderWidth: 10,
        labels: this.statusBarChart.labels,
        datasets: [
          {
            backgroundColor: this.statusBarChart.colors,
            data: this.statusBarChart.data
          }
        ]
      };
    },

    getTotalNumbersOfShippedItems() {
      return this.statusBarChart.data.reduce((a, b) => a + b, 0)
    },
  },

  methods: {
    async getData() {
      try {
        this.loader = this.$loading.show({
          container: this.$refs.chartsContainer
        });


        let res = await this.axios.get("projects/member-data");
        this.numberOfShippedPieChartData = res.data.numberOfShippedPieChartData;
        this.numberOfShippedBarChartData = res.data.numberOfShippedBarChartData;
        this.statusPieChart = res.data.statusPieChart;
        this.statusBarChart = res.data.statusBarChart;
        this.stateTableData = res.data.dataByState;
        this.stateTableColumns = res.data.stateTableColumns;

        this.countriesChartData = res.data.geoCountriesData;
        if (this.countriesChartData.length > 0)
          this.countriesChartData.unshift(['Country', 'Name', 'Packages Number', {role: 'tooltip', p: {html: true}}]);
        else this.countriesChartData.unshift(['country', 'Name'])

        this.usaChartData = res.data.geoUsaData;
        if (this.usaChartData.length > 0)
          this.usaChartData.unshift(['State', 'Name', 'Packages Number', {role: 'tooltip', p: {html: true}}]);
        else this.usaChartData.unshift(['State', 'Name']);

        this.topCountriesColumns = res.data.topCountriesColumns;
        this.topCountriesData = res.data.topCountriesData;

        this.topUsaStatesColumns = res.data.topUsaStatesColumns;
        this.topUsaStatesData = res.data.topUsaStatesData;

        this.deliveryPercentage = res.data.deliveryPercentage;
        this.deliveredLastYear = res.data.deliveredLastYear;
        this.projectsDeliveryPercentage = res.data.projectsDeliveryPercentage;
        this.requestDone = true;

      } catch (e) {
        console.log(e);
      } finally {
        this.loader.hide();
      }
    },

  }


}

</script>
<style scoped>


.project_delivery_percentage {
  display: flex;
  align-items: center;
  margin: 1rem !important;
}

.project_title {
  width: 25%
}

.project_title h4 {
  margin: 0;
  font-size: 1rem;
}

.project_progress {
  width: 75%
}

@media only screen and (max-width: 1100px) {
  .project_delivery_percentage {
    flex-direction: column;
    align-items: flex-start;
    margin: 2rem !important;
  }

  .project_title {
    width: 100%
  }

  .project_progress {
    width: 100%
  }
}


</style>
