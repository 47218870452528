import Vue from 'vue'
import VueRouter from "vue-router";
import routes from './routes';
import store from '@/store';

Vue.use(VueRouter)

const router = new VueRouter({
  routes,
  linkActiveClass: 'active',
  mode: 'history'
});


router.beforeEach((to, from, next) => {

  if (['Login', 'Forget Password', 'Reset Password'].includes(to.name)
    && store.getters['auth/authenticated']) { // trying access login when user has token
    return next({
      name: 'Overview'
    });
  } else if (!['Login', 'Forget Password', 'Reset Password', 'Not Found'].includes(to.name)
    && !store.getters['auth/authenticated']) { // trying access pages without authentication
    return next({
      name: 'Login'
    });
  }

  return next();
});

export default router;
