<template>
  <ul class="nav nav-mobile-menu">

    <drop-down position="right">
      <i slot="title" class="nc-icon nc-chart-bar-32"></i>
      <a v-for="p in userProjects" href="javascript:void(0)"
         style="text-align: left"
         @click.prevent="$router.push('/projects/'+p.uuid+'/preview')" class="dropdown-item project-item">
        {{ p.title }}
      </a>
      <li class="divider"></li>
      <a href="javascript:void(0)"
         @click.prevent="$router.push('/analytics')" class="dropdown-item project-item">
        Show All
      </a>
    </drop-down>

    <drop-down>
      <template slot="title">
        <i class="nc-icon nc-single-02"></i>
      </template>
      <a @click.prevent="$router.push('/update-profile')" href="javascript:void(0)" class="dropdown-item">
        <i class="nc-icon nc-single-02"></i>My Profile
      </a>
      <a @click.prevent="$router.push('/change-my-password')" href="javascript:void(0)" class="dropdown-item">
        <i class="nc-icon nc-key-25"></i>Change Password
      </a>
      <a @click.prevent="logout()" href="javascript:void(0)" class="dropdown-item text-danger">
        <i class="nc-icon nc-button-power"></i> Log Out
      </a>
    </drop-down>
  </ul>
</template>
<script>
import DropDown from 'src/components/Dropdown.vue'
import {mapActions, mapGetters} from "vuex";

export default {
  components: {
    DropDown
  },

  computed: {
    ...mapGetters({
      userProjects: "global/userProjects"
    })
  },
  methods: {
    ...mapActions({
      logoutAction: 'auth/logout'
    }),

    logout() {
      this.logoutAction().then(() => {
        this.$router.push("/login");
      });
    },
  }
}
</script>
<style>
</style>
