<template>
  <fragment>
    <sidebar-item :link="{name: node.name, path: getPath}"></sidebar-item>
    <sidebar-tree v-for="(child,index) in node.children"
                  :node="child"
                  :key=index
                  ></sidebar-tree>
  </fragment>
</template>
<script>
export default {

  name: "sidebar-tree",
  props: {
    node: Object
  },
  computed: {
    getPath: function () {
      return "/companies/" + this.node.uuid + "/projects";
    },

  }
}

</script>
